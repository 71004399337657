import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  // Title,
  // Tooltip,
  // Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,

);

export const options = {
  responsive: true,
  locale: 'en-US',
  scales: {
    y: {
      min: 0,
      // max: 13700000,
    }
  },
  plugins: {
    legend: {

    },
    title: {
      display: true,
      text: 'Total Treasury Assets',
    },
  },
};

const labels = ['July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July'];
export const data = {
  labels,
  datasets: [
    {
      fill: true,
      // lineTension: 0.9,
      label: 'HDN',
      pointRadius: 4,
      pointHoverRadius: 8,
      pointBackgroundColor: "#9614C4",
      data: [11549117, 10645973, 8876744, 8438041, 7226448, 7877099, 7265354, 5767180, 5073178, 9358881, 7894974, 6800265, 5805640],
      borderColor: '#ba16f5',
      backgroundColor: (context: ScriptableContext<"line">) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 250);
        gradient.addColorStop(0, "#9614C4");
        gradient.addColorStop(1, "#9614c430");
        return gradient;

      },
    },
    // {
    //   fill: true,
    //   label: 'HDN',
    //   data: [12.400, 11.282, 11.282, 11.282, 11.282, 11.282, 11.282],
    //   borderColor: 'rgb(53, 162, 235)',
    //   backgroundColor: (context: ScriptableContext<"line">) => {
    //     const ctx = context.chart.ctx;
    //     const gradient = ctx.createLinearGradient(0, 0, 0, 250);
    //     gradient.addColorStop(0, "#1ba777");
    //     gradient.addColorStop(1, "rgba(64,51,57,0)");
    //     return gradient;

    //   },
    // },
  ],
};




export default function HdnChanges() {
  return (


    <div className='card card-style-2'>
      <div class="card-top d-flex justify-content-between align-items-center">
        <h3 class="card-title">DAO HDN Treasury Changes</h3>
        <div class="card-value">$129,475
        </div>
      </div>

      <div className='chart'>
        <Line options={options} data={data} />
      </div>
    </div>
  )
}

