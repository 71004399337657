import React from 'react'
import GovernanceChart from '../components/GovernanceChart'

export default function governance() {
  return (
    <section className='section section-governance mt-4'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 mb-5'>
            <div className='page-title d-flex justify-content-start align-items-center'>
              <div className='title-icon'><i class="fa-solid fa-chess-queen"></i></div>
              <div className='title-content'>
                <h1>Governance</h1>
                <p>DAO Proposals & Stats</p>
              </div>
            </div>
          </div>

          <div className='col-lg-12 mb-5'>
            <GovernanceChart></GovernanceChart>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-32'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x8f11b894c1f6b107f7e99892fc57c32ddce8ec6ad4c15a629016cb2bb4cc6ebf">
                  #32 Closing Core Dex Server for cost saving and migration to the Web Dex server
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Jul 23, 2024</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
              This proposal outlines the plan to close the Core Dex server to facilitate the upcoming launch of Web Dex.</p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="100" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '100%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>100%</span>
                      <span>Approval</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="0%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '0%' }}>
                    <div className='proposal-options'>
                      <span>0%</span>
                      <span>Rejection</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-29'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x715a967208b2fa3de4675826445cc74f2bc1e6ece28fd501c3cfc2a2ef2a408f">
                  #31 Establishment of Hydranet DAO Proposal Governance Structure
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>May 5, 2024</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
              This proposal seeks to allocate 5 million HDN from the Hydranet DAO Vault to fund ongoing and future development.</p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="100" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '100%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>100%</span>
                      <span>Approve</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="0%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '0%' }}>
                    <div className='proposal-options'>
                      <span>0%</span>
                      <span>Reject</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-31'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x95c33b7ba317ba09d3836fd48fb771157a3c4584fefdc9674044c4fb957ab2cd">
                  #30 - Funding Development with 5 Million HDN from the Vault
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Apr 13, 2024</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
              This proposal seeks to allocate 5 million HDN from the Hydranet DAO Vault to fund ongoing and future development.</p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="61.93" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '61.93%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>61.93%</span>
                      <span>Approval of the 5 million HDN</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="36.41%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '36.41%' }}>
                    <div className='proposal-options'>
                      <span>36.41%</span>
                      <span>Request for further information</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-30'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0xf5e81fba4aef97188459bc010f633c884ccf4e6da52c1c1177f8bf07885e5860">
                    #29 Update HDN Tokenomics - add HDN Collateral and Fee
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Feb 28, 2024</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
              This proposal outlines updates to the Hydranet DEX Network, specifically focusing on the HDN collateral requirements and the distribution model for trading fees. These updates aim to enhance the network's security, efficiency, and overall value proposition for HDN holders and users.</p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="95.18%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '95.18%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>95.18%</span>
                      <span>Approve the proposed updates</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="4.82%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '4.82%' }}>
                    <div className='proposal-options'>
                      <span>4.82%</span>
                      <span>Reject the proposed updates</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-29'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x3751775ad5a29720360e6a8b05a195f7faf999f5070bf148d8ce241b72458dac">
                    #28 Community Approval for Tier-2 CEX Listings
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Feb 6, 2024</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
              Community approval is required for new exchange listings. Tier 1 exchanges demand stronger statistics for listings and charge higher listing fees. In this context, directing the listing strategy to Tier 2 exchanges depends on the community's decision.</p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="87.54%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '87.54%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>87.54%</span>
                      <span>Approve Tier-2 listings</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="12.46%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '12.46%' }}>
                    <div className='proposal-options'>
                      <span>12,46%</span>
                      <span>Reject All Short Term</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-27'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x771426ec35dfb2b551c328a7077a40e6a16972fe99efadc7340c97b0fc369d37">
                    #27 Hydranet Multi-Sig Re-Election and Responsibilities

                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Jan 22, 2024</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
              This proposal is for the re-election of the Hydranet multi-sig members, outlining their individual responsibilities. The DAO community is invited to participate in the voting process to re-elect the multi-sig representatives.</p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="100%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '100%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>100%</span>
                      <span>Yes</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="0%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '0%' }}>
                    <div className='proposal-options'>
                      <span>0%</span>
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-26'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x9a6210aaca0d512c362cdd560d69df9e3f59fb76e9e130a3e10357690e8bb3c0">
                    #26 Changing Our Funding Mechanisms Regarding Bonds

                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Jan 6, 2024</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
              This proposal addresses the future of fundraising within the Hydranet DAO, specifically focusing on transitioning from selling bonds to a new mechanism involving the disbursement of HDN tokens to a multi-sig-controlled address for grants and funding operations.</p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="100%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '100%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>100%</span>
                      <span>Approve</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="0%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '0%' }}>
                    <div className='proposal-options'>
                      <span>0%</span>
                      <span>Reject</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-25'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0xd355a01099029f5ff9652dd69511d0f983df5913e178437753205ad20bcd75a9">
                    #25 DAO Approval for updated version of Tokenomics

                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Dec 6, 2023</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
              Over the past few months, our team has dedicated substantial effort and time to maturing our project's tokenomics...</p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="100%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '100%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>100%</span>
                      <span>Yes</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="0%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '0%' }}>
                    <div className='proposal-options'>
                      <span>0%</span>
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-24'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0xcd88cff1f02b487dae7fef7b038d5053b895381d3a0a07e61d146fa79dbc649b">
                    #24 Approval of Hydranet DAO Roadmap (Q4 2023 - Q3 2024)

                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Oct 15, 2023</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
              This proposal seeks approval from the DAO to present the Hydranet DAO's strategic roadmap for the next year, outlining our key initiatives...</p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="98.21%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '98.21%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>98.21%</span>
                      <span>Approve Roadmap</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="1.79%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '1.79%' }}>
                    <div className='proposal-options'>
                      <span>1.79%</span>
                      <span>Deny Roadmap</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-22'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x3f65c1e15e58ecb4d5926fadcfc8efbfc86db736ce6d3083d9e42e98346038c2">
                    #23 Establishment of the Hydranet Foundation

                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Oct 2, 2023</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
              This proposal seeks the DAO's approval to establish the "Hydranet Foundation," a legally incorporated entity that will serve as a legal wrapper for the DAO,
               </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="98.98%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '98.98%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>98.98%</span>
                      <span>For Yes</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="1.02%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '1.02%' }}>
                    <div className='proposal-options'>
                      <span>1.02%</span>
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-22'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0xa521fa07891cedc496879c716b2bafaaaad19f8793cd5cbe93b112d48f28e120">
                    #22 Allocation of 1 Million HDN for Exchange Listings

                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Sep 27, 2023</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
              This proposal seeks the DAO's approval to allocate 1 million HDN from the treasury to facilitate our token's listing on exchange platform(s).
               </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="83.37%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '83.37%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>83.37%</span>
                      <span>For Yes</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="16.63%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '16.63%' }}>
                    <div className='proposal-options'>
                      <span>16.63%</span>
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-21'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x9c31ebf6e4747fc0447761218ce61c8138e8943db333c8cd3dbcde05148734b6">
                    #21 Releasing the DEX Source Code to the DAO for Decentralized Governance...
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Sep 22, 2023</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
              This proposal seeks the DAO's endorsement to transition the ownership...
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="100%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '100%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>100%</span>
                      <span>For Yes</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="0%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '0%' }}>
                    <div className='proposal-options'>
                      <span>0%</span>
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-20'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0xf65ea370050507b9ef2d6d64727c38e2d1bd033ab5404575f2d0def2a5128100">
                    #20 Hydranet improvement Proposal: Add Open Sourcing on the Hydranet Roadmap
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Sep 22, 2023</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
              This proposal introduces a structured open-sourcing strategy... 
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="70.74%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '70.74%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>70.74%</span>
                      <span>For Yes</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="24.61%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '24.61%' }}>
                    <div className='proposal-options'>
                      <span>24.61%</span>
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-19'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x2654e136861fd86057da3921c201e6aa3c2c93d835c2f433c5c32b51d5a06f3e">
                    #19 DEX Fee Discount Addition to Tokenomics
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Apr 20, 2023</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                Adding to the HDX Tokenomics as a simplified version of existing DNFT ideas...
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="99.81%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '99.81%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>99.81%</span>
                      <span>For Yes</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="0.19%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '0.19%' }}>
                    <div className='proposal-options'>
                      <span>0.19%</span>
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-18'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x1712d7a502b7a9ff2e061567797f094866249fd7adb227ff1a28e200a149a60f">
                    #18 Hydranet Ticker Change
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Mar 7, 2023</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                We have recently voted on the top two tickers the community would rather have over HDX...
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="HDN" ariaValuenow="76.39%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '76.39%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>76.39%</span>
                      <span>For HDN</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="HDT" ariaValuenow="22.21%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '22.21%' }}>
                    <div className='proposal-options'>
                      <span>22.21%</span>
                      <span>HDT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-17'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x23589cca091ca1967ed98d2ef9fbf40dd4c7017c4d7b2d99faad359df68161f0">
                    #17 5 Million for Bonds
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Feb 18, 2023</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                As we progress toward our goal of a release, HUB infrastructure will need upgrades to support higher demands...
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="88.7%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '88.7%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>88.7%</span>
                      <span>For Yes</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="21.45%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '21.45%' }}>
                    <div className='proposal-options'>
                      <span>11.3%</span>
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-16'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x7b5650146989fa334bd2b8131dae6f5abc142c2045c1862e9e5b1b9ae210827e">
                    #16 HDX Staking APY
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Feb 13, 2023</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                Currently, there is no benefit to the project or the holders for staking on arbitrum since we are not verifying on-chain...
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="0% APY" ariaValuenow="78.55%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '78.55%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>78.55%</span>
                      <span>For 0% APY</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="21.45%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '21.45%' }}>
                    <div className='proposal-options'>
                      <span>21.45%</span>
                      <span>7.5% APY</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-15'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x116bd6489e4f07471e1f0a8704ea0e79b1bd2310be46cf522f9f038f4eafffcc">
                    #15 Voting on Tokenomics v1
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Feb 3, 2023</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                Community support of this tokenomics version will serve as a general road map while navigating the main net launch...
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="99.46%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '99.46%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>99.46%</span>
                      <span>For Yes 99.46%</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="0.54%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '0.54%' }}>
                    <div className='proposal-options'>
                      <span>0.54%</span>
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-14'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0xc087dab42cf90225efb7b1c5946b3cf0e0b7408809d03405b3310db19b509f4a">
                    #14 Officially voting in current multi-sigs
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Jan 24, 2023</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                The current DAO representatives have helped in marketing, organization, media creation, tokenomics, planning, testing, and game theory.
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes" ariaValuenow="99.48%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '99.48%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>99.48%</span>
                      <span>For Yes 99.48%</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No" ariaValuenow="0.52%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '0.52%' }}>
                    <div className='proposal-options'>
                      <span>0.52%</span>
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-13'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0xc20a78faf2285fca755bde0250316524e677ef296b6d22ac3d5a9362fbd85057">
                    #13 Yes, add more assets
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Sep 25, 2022</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                Should we add new assets to the HDX treasury? Our treasury currently consists of GLP, a token representing USDC, ETH, WBTC, DAI...
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes, add more assets" ariaValuenow="95.56%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '95.56%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>95.56%</span>
                      <span>Yes, add more assets</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No, only hold GLP" ariaValuenow="4.44%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '4.44%' }}>
                    <div className='proposal-options'>
                      <span>4.44%</span>
                      <span>No, only hold GLP</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-12'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0xd4cb329b1f0bf1ab898a86a228fec58b3583829f4b117a7ec804ff0354f6dbaf">
                    #12 Staking rewards - DAO vote
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Aug 28, 2022</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                We have successfully fixed the rebase bug that lead to the one time peak of staking rewards in July and successfully...
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Mint circa 5.5 Million HDX" ariaValuenow="82.06%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '82.06%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i>82.06%</span>
                      <span>Mint circa 5.5 Million HDX</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No mint, no staking rewards" ariaValuenow="17.94%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '17.94%' }}>
                    <div className='proposal-options'>
                      <span>17.94%</span>
                      <span>No mint, no staking rewards</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-11'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0xee8910d97d91482c6c0e69f7be87985ab56cc9d465dc54b2d9e6aeb4d7414ae1">
                    #11 Tokenomics - HDX DAO Vote
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Jul 5, 2022</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                One team member, Beljora, came up with a new idea: Stakers can claim their fees at any time, but after a period of 1 year...
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Beljora's idea" ariaValuenow="90.88%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '90.88%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i> 90.88%</span>
                      <span>For, Beljora's idea</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="3 Months" ariaValuenow="37.64%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '37.64%' }}>
                    <div className='proposal-options'>
                      <span>37.64%</span>
                      <span>3 Months</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-10'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0xa4da869b2417257bda389476c8d9514312f2bceb4d917cab47046e9a151999f5">
                    #10 Tokenomics - HDX DAO Vote
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Jul 1, 2022</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                We plan to distribute the DEX fees to HDX stakers - more details to be announced later with the Tokenomics release.
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes, re-distribute fees" ariaValuenow="90.88%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '90.88%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i> 90.88%</span>
                      <span>For, Yes, re-distribute fees</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No, let these funds vanish" ariaValuenow="9.12%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '9.12%' }}>
                    <div className='proposal-options'>
                      <span>9.12%</span>
                      <span>No, let these funds vanish</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-09'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0xb40ff247c5d8f27121b2a30bb7527c4f1e80633775b048cdf55f0a7f8ee145f4">
                    #9 HDX APY of 7.5 % - DAO vote
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>June 6, 2022</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                As the previous vote has been so super close, we are currently thinking about making a compromise, aiming for 7.5% APY.
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Yes, 7.5% APY as compromise" ariaValuenow="82.1%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '82.1%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i> 82.1%</span>
                      <span>For, Yes, 7.5% APY as compromise</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="No, start new discussions" ariaValuenow="17.9%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '17.9%' }}>
                    <div className='proposal-options'>
                      <span>17.9%</span>
                      <span>No, start new discussions</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-08'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0xb40ff247c5d8f27121b2a30bb7527c4f1e80633775b048cdf55f0a7f8ee145f4">
                    #8 HDX APY - Community Vote
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>June 5, 2022</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                We have researched the possible methods to lower the APY, deployed it on the testnet, and analyzed the results.
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Decrease staking rewards to ~10% " ariaValuenow="48.62%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '48.62%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i> 48.62%</span>
                      <span>For, Decrease staking rewards to ~10%</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="Decrease staking rewards to ~5% " ariaValuenow="46.36%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '46.36%' }}>
                    <div className='proposal-options'>
                      <span>46.36%</span>
                      <span>NO, Argon</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-07'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x464258ce95c85e8cfb77bf0aa5b44978544956c28081cf3fb9443036d20fa08e">
                    #7 POL Strategy
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>May 26, 2022</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                We want to know your opinion! We have heard your concerns about the current Uniswap liquidity situation, that's why we want to hear your...
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="Alperen 02" ariaValuenow="62.69%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '62.69%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i> 62.69%</span>
                      <span>For, Alperen 02</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="Argon" ariaValuenow="34.49%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '34.49%' }}>
                    <div className='proposal-options'>
                      <span>34.49%</span>
                      <span>NO, Argon</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-06'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x33858831d729c8edba72cb975fb5b5b5007e57df475c108743e1a711159e44aa">
                    #6 Hydra Bridge
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>May 8, 2022</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                This idea was taken over by the old team, but we soon realized it won't be easy as initially planned. Yet we kept on working on it, to be able to release some...
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="YES, focus on DEX only" ariaValuenow="86.88%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '86.88%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i> 86.88%%</span>
                      <span>For, YES, focus on DEX only</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="Yes, keep HDX reserved for now" ariaValuenow="13.12%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '13.12%' }}>
                    <div className='proposal-options'>
                      <span>13.12%</span>
                      <span>NO, work on the bridge too</span>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-05'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x33858831d729c8edba72cb975fb5b5b5007e57df475c108743e1a711159e44aa">
                    #5 Reserve HDX for Cryptopia Coins
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>May 1, 2022</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                Should we keep ~ 4,52 million HDXs as a reserve for the Cryptopia holders, who most likely will receive their coins by end of the year 2022?..
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="No, burn all of them" ariaValuenow="62.32%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '62.32%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i> 62.32%</span>
                      <span>For, No, burn all of them</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="Yes, keep HDX reserved for now" ariaValuenow="37.68%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '37.68%' }}>
                    <div className='proposal-options'>
                      <span>37.68%</span>
                      <span>Yes, keep HDX reserved for now</span>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-04'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x02117c10058b716b8089313fd54d3424f6b95500cf80d63e749dba883e740728">
                    #4 Finish the swap date on the 20th of May, instead of the 30th of June 2022?
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Apr 25, 2022</span>
                </div>
                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                The previously announced date to end the swap was June 30th. This was proposed by the old team, and the new team agreed with limited understanding...
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="The previously announced date to end the swap was June 30th" ariaValuenow="94.92%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '94.92%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i> 94.92%</span>
                      <span>For 11M HDX</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="The previously announced date to end the swap was June 30th" ariaValuenow="3.29%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '3.29%' }}>
                    <div className='proposal-options'>
                      <span>Against 373K HDX</span>
                      <span>3.29%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-03'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0x60d0ad8ed6a26d7671e55351a9e0cecd8ec5c03bf1cdc71e8f2365b9a73017e0">
                    #3 Should we use GLP by GMX as our treasury asset?
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Apr 22, 2022</span>
                </div>

                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                GMX is a derivates DEX, already handled about 30 Billion USD trading volume. For liquidity, GMX is using GLP.
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="High Concentrated Hydra POL 8.7M HDX" ariaValuenow="97.92%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '97.92%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i> 97.92%</span>
                      <span>For 11M HDX</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="Low Concentrated Hydra POL 2M HDX" ariaValuenow="2.08%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '2.08%' }}>
                    <div className='proposal-options'>
                      <span>Against 239K HDX</span>
                      <span>2.08%</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-02'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer"
                    href="https://snapshot.org/#/hydranet.eth/proposal/0xc6a031ec59c0ffe6976db090014316049af19e795d0fb93e42c287612dae7fed">
                    #2 Brand logo/Token logo change
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>Apr 11, 2022</span>
                </div>

                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                HDX community member Argon has created a new logo for HydraNet that can be used for branding. Image below. Custom_Size_14.png If passed, HDX will use...
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="High Concentrated Hydra POL 8.7M HDX" ariaValuenow="80.92%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '80.92%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i> 100.00%</span>
                      <span>For 9.9M</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="Low Concentrated Hydra POL 2M HDX" ariaValuenow="80.92%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '0%' }}>
                    <div className='proposal-options'>
                      <span>Against</span>
                      <span>0%</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>



          <div className='col-lg-6 mb-5'>
            <div className='proposal vote-01'>
              <div className='proposal-header'>
                <div className='proposal-header-content'>
                  <h2><a target="_blank" rel="noopener noreferrer" href="https://snapshot.org/#/hydranet.eth/proposal/0xe45b21491e850afc7f29f8ad20cc5459173a7ca959b21bba72f8edb3dd03c6e1">
                    #1 POL Liquidity Strategy for HDX
                  </a>
                  </h2>
                  <span className='proposal-timestamp'>March 23, 2022</span>
                </div>

                <span className='proposal-status closed'>Closed</span>
              </div>
              <p className='proposal-summary'>
                The HDX chain is now live! We want to begin governance with our first vote to feel out how the community prefers our POL to be concentrated. This will be
              </p>
              <div className='proposal-results'>
                <div className="progress" role="progressbar" ariaLabel="High Concentrated Hydra POL 8.7M HDX" ariaValuenow="80.92%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '80.92%' }}>
                    <div className='proposal-options'>
                      <span><i className="fa-regular fa-circle-check d-inline"></i> 80.92%</span>
                      <span>Low Concentrated Hydra POL 2M HDX</span>
                    </div>
                  </div>
                </div>
                <div className="progress" role="progressbar" ariaLabel="Low Concentrated Hydra POL 2M HDX" ariaValuenow="80.92%" ariaValuemin="0" ariaValuemax="100">
                  <div className="progress-bar progress-bar-striped" style={{ width: '19.08%' }}>
                    <div className='proposal-options'>
                      <span>19.08%</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </section>
  )
}
