import React from 'react';
import { Routes, Route } from "react-router-dom"
import Dashboard from './pages/dashboard';
import Layout from './pages/layout';
import Governance from './pages/governance';
import Faqs from './pages/faqs';
import './App.css';
import './icons.css';

function App() {
  return (
    <Routes>  
      <Route path='/' element={<Layout />} >
        <Route index={true} element={<Dashboard />} />
        <Route path='governance' element={<Governance />} />
        <Route path='faqs' element={<Faqs />} />
      </Route>
    </Routes> 
  );    
} 

export default App;  
