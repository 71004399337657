import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  // Title,
  // Tooltip,
  // Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,

);

export const options = {
  responsive: true,
  locale: 'en-US',
  scales: {
    y: {
      min: 0,
      max: 45000,
    }
  },
  plugins: {
    legend: {

    },
    title: {
      display: true,
      text: 'DAO USDC Treasury Changes',
    },
  },
};

const labels = ['July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July'];
export const data = {
  labels,
  datasets: [
    {
      fill: true,
      // lineTension: 0.9,
      label: 'USDC',
      pointRadius: 4,
      pointHoverRadius: 8,
      pointBackgroundColor: "#33c632",
      data: [30958, 21067, 22701, 11115, 11115, 41119, 41310, 39310, 39300, 31826, 26708, 25860, 24645],
      borderColor: '#33c632',
      backgroundColor: (context: ScriptableContext<"line">) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 250);
        gradient.addColorStop(0, "#219b20");
        gradient.addColorStop(1, "#219b2030");
        return gradient;

      },
    },
    // {
    //   fill: true,
    //   label: 'HDN',
    //   data: [12.400, 11.282, 11.282, 11.282, 11.282, 11.282, 11.282],
    //   borderColor: 'rgb(53, 162, 235)',
    //   backgroundColor: (context: ScriptableContext<"line">) => {
    //     const ctx = context.chart.ctx;
    //     const gradient = ctx.createLinearGradient(0, 0, 0, 250);
    //     gradient.addColorStop(0, "#1ba777");
    //     gradient.addColorStop(1, "rgba(64,51,57,0)");
    //     return gradient;

    //   },
    // },
  ],
};




export default function USDCChanges() {
  return (


    <div className='card card-style-2'>
      <div class="card-top d-flex justify-content-between align-items-center">
        <h3 class="card-title">DAO USDC Treasury Changes</h3>
        <div class="card-value">$24,645</div>
      </div>


      <div className='chart'>
        <Line options={options} data={data} />
      </div>


    </div>
  )
}

