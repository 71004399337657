import React from 'react'

import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip);

export const treasureExcludingHDN = {
    labels: ['USDT', 'USDC', 'ETH'],
    datasets: [
      {
        data: [54527, 24645, 28806],
        
        backgroundColor: [
          '#1284ff',
          '#9614C4',
          '#e8e708',
        ],
        borderColor: [
            '#1284ff',
            '#9614C4',
            '#e8e708',
        ],
        hoverOffset: 0,
        borderWidth: 1,
        cutout: 38,
        spacing: 0,
        // circumference: 360,
      },

    ],
  };

export default function TreasureExcludingHDN() {
  return (
    <div className='card card-style-1'>
    <div className=' d-flex justify-content-between'>
        <div className='card-left'>
            <h3 className='card-title'>Treasury Excluding HDN</h3>
            <div className='card-value'>$107,978</div>
            <div className='sub-text'>+$15,647 Last Month</div>
        </div>
        <div className='chart-right'>
        <Doughnut data={treasureExcludingHDN} />
        </div>
    </div>
</div>
  )
}
