import React from 'react'

import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip);

export const TotalTreasuryChart = {
    labels: ['HDN', 'USDT', 'USDC', 'ETH'],
    datasets: [
      {
        label: '$',
        data: [129475, 54527, 24645, 28806],
        
        backgroundColor: [
          '#1284ff',
          '#D64E11',
          '#1ba777',
          '#8930EF',

        ],
        borderColor: [
            '#1284ff',
            '#D64E11',
            '#1ba777',
            '#8930EF',

        ],
        hoverOffset: 0,
        borderWidth: 1,
        cutout: 40,
        spacing: 0,
        // circumference: 360,
      },
      
    ],



  };

export default function TotalTreasury() {
  return (
    <div className='card card-style-1'>
    <div className=' d-flex justify-content-between'>
        <div className='card-left'>
            <h3 className='card-title'>DAO Total Treasury</h3>
            <div className='card-value'>$237,455
</div>
            <div className='sub-text'>- $13,316 Last Month</div>
        </div>
        <div className='chart-right'>
        <Doughnut data={TotalTreasuryChart} />
    
        </div>
    </div>
</div>
  )
}
