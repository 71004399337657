import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  // Title,
  // Tooltip,
  // Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,

);

export const options = {
  responsive: true,
  locale: 'en-US',
  scales: {
    y: {
      min: 0,
      // max: 2200000,

    }
  },
  plugins: {
    legend: {

    },
    title: {
      display: true,
      text: 'Total Treasury Assets',
    },
  },
};

const labels = ['July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July'];
export const data = {
  labels,
  datasets: [
    {
      fill: true,
      // lineTension: 0.9,
      label: '$',
      pointRadius: 4,
      pointHoverRadius: 8,
      pointBackgroundColor: "#1387F1",
      data: [ 787786, 1718679, 955287, 914681, 1105671, 840709, 653849, 637020, 343203, 429894, 383000, 250771, 237455],
      borderColor: '#0B6FC9',
      backgroundColor: (context: ScriptableContext<"line">) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 250);
        gradient.addColorStop(0, "#1387F1");
        gradient.addColorStop(1, "rgba(64,51,57,0)");
        return gradient;

      },
    },
    // {
    //   fill: true,
    //   label: 'Dataset 2',
    //   data: [18, 29, 32, 92, 48, 64, 89, 72],
    //   borderColor: 'rgb(53, 162, 235)',
    //   backgroundColor: 'red',
    // },
  ],
};




export default function TreasuryChanges() {
  return (


    <div className='card card-style-2'>
      <div class="card-top d-flex justify-content-between align-items-center">
        <h3 class="card-title">Treasury Changes</h3>
        <div class="card-value">$237,455
        </div>
      </div>

      <div className='chart'>
        <Line options={options} data={data} />
      </div>
    </div>
  )
}

