import React from 'react'
import Update from '../assets/update.svg'
import DateFilter from '../components/DateFilter'
import TotalTreasury from '../components/TotalTreasury'
import TreasureExcludingHDN from '../components/TreasureExcludingHDN'
import TreasuryChanges from '../components/TreasuryChanges'
import HdnChanges from '../components/HdnChanges'
import USDTChanges from '../components/USDTChanges'
import USDCChanges from '../components/USDCChanges'

import '../dashboard.css';


export default function dashboard() {
    return (
        <>
            <section className='section section-filter mt-4'>
                <div className='container'>
                    <div className='row g-0 justify-content-between align-items-center'>
                        <div className='col-lg-auto col-4'>
                            <DateFilter></DateFilter>
                        </div>
                        <div className='col-lg-auto col-8 version-column'>
                            <ul className='version-info d-flex justify-content-end'>
                                <li><img src={Update} alt='Chart'></img>Last Update: July 30, 2024</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <section className='section section-cards-2 mt-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <TotalTreasury></TotalTreasury>
                        </div>
                        <div className='col-lg-6'>
                            <TreasureExcludingHDN></TreasureExcludingHDN>
                        </div>
                    </div>
                </div>
            </section>


            <section className='section section-cards-4 mt-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <TreasuryChanges></TreasuryChanges>
                        </div>
                        <div className='col-lg-6'>
                            <HdnChanges />
                        </div>
                    </div>
                </div>
            </section>

            <section className='section section-cards-4 mt-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <USDTChanges></USDTChanges>
                        </div>
                        <div className='col-lg-6'>
                            <USDCChanges />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
