import React from 'react'

export default function DateFilter() {
  return (
    <div className='date-filter'>
        <div className='filter-title'>Total Supply:</div>
        <ul className='filter-options'>
            <li><button className='filter-button active' disabled>300M HDN</button></li>
            {/* <li><button className='filter-button' disabled>24 Hours</button></li> */}
            {/* <li><button className='filter-button active'>30 Days</button></li>
            <li><button className='filter-button'>Last Year</button></li> */}
        </ul>
    </div>
  )
}
